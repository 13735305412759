import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import yn from "yn";

import {
  GridListTile,
  GridSpacing,
  Theme,
  useMediaQuery
} from "utils/material-ui-core";

import { queryProduct } from "graphql-client-product";
import { SaleContextFilter } from "graphql-client/queries/app";
import { queryRideAlongProduct } from "graphql-client/queries/base-product";
import { queryDraperyLink } from "graphql-client/queries/drapery-link";
import { queryFullSkuProduct } from "graphql-client/queries/full-sku-product";
import { queryGiftCardTerms } from "graphql-client/queries/gift-card-terms";
import { queryProductSwatchImage } from "graphql-client/queries/product-swatch-image";

import { addOnDataType } from "component-product-addon/types";
import { MultiSkuComponentWithRestrictions } from "components/RelatedProductCard/types";

import useBrand from "hooks-use-brand/useBrand";
import useLocale from "hooks-use-locale/useLocale";
import useAppData from "hooks/useAppData";
import { useChangeOnce } from "hooks/useChangeOnce";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useEnv } from "hooks/useEnv";
import { useFetchParams } from "hooks/useFetchParams/useFetchParams";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useLocalization } from "hooks/useLocalization";
import useMeasurementType from "hooks/useMeasurementType";
import useParams from "hooks/useParams";
import useSite from "hooks/useSite";
import { processEnvServer } from "hooks/useSsrHooks";
import useState from "hooks/useState";
import useUserPreferences from "hooks/useUserPreferences";
import useMediaString from "hooks/useMediaString";
import useResponsiveGridSpacing from "hooks/useResponsiveGridSpacing";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import useUnsafeEffect from "hooks/useUnsafeEffect";
import { useAppId } from "hooks/useAppId";

import {
  IMAGE_ASPECT_RATIO,
  INITIAL_PG_IMAGE_CONTAINER_DIMENSION,
  PAGE_BG_GREY,
  PG_IMAGE_CONTAINER_DIMENSION,
  PG_SEARCH_TERM,
  POSTAL_CODE_COOKIE
} from "utils/constants";
import { convertPathLocale, getLocaleFromPath } from "utils/intlUtils";
import { getReqContext } from "utils/reqContext";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { isGTMLoaded } from "utils/analytics/utils";
import { addPgTermFallback } from "utils/pgTerm";
import prasePGCropRules from "utils/prasePGCropRules";
import { getMemberSavingsText } from "utils/memberSavingsTextUtils";
import { queryUpsSellProducts } from "graphql-client-product";
import { queryRelatedProducts } from "graphql-client/queries/related-product";
import { useFetchModel } from "hooks/useFetchModel";
import { usePrintHijack } from "printable-pages/usePrintHijack";
import { getCookie } from "utils/cookies";
import { objectToQueryString } from "utils/objectToQueryString";
import {
  isSSRToggledCheck,
  isSSRToggledWithClientRender
} from "utils/ssrHelpers";
import { useRhUserAtomValue } from "hooks/atoms";
import { useKeycloak } from "utils/Keycloak/KeyCloak";
import { defaultRideAlongData } from "./constants";
import { RideAlongData, AddAllItemToCart } from "./types";
import template from "lodash/template";
import { isEmpty } from "lodash";
import ProductCard from "component-product-card";
import { getSelectorsByUserAgent } from "react-device-detect";
import { cloudCollectionUrlPattern } from "utils/regex";

let swatchToggle = 0;

export const useProductData = props => {
  const { productItem, isLoading } = props;

  let _upsellProducts,
    _refetchUpSellProducts,
    _relatedProductVariable,
    _relatedProductsQuery,
    _relatedProductsloading;

  /**
   * Constants
   * */
  const w = (processEnvServer ? null : window) as any;
  const filePathRegex =
    /http:\/\/images\.restorationhardware\.com\/content\/catalog\/(.*)\.pdf/;

  // const skipCall = isLoading === true || !!productItem?.id;
  const DEFAULT_GRID_COL = 4;

  /**
   * CSS Styles / Classes
   * */
  const gridSpacing = useResponsiveGridSpacing();
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  /**
   * AEM Page Content
   * */
  const { pageContent } = useFetchModel("/admin/product", true, false);

  /**
   * Custom Hooks
   * */
  const req: any = processEnvServer ? getReqContext() : false;
  let params = useParams({
    productId: "",
    fullSkuId: "",
    sale: "",
    swatch: "",
    inStock: ""
  });
  const { app } = useAppData();
  const pc = useIsoCookies([POSTAL_CODE_COOKIE])?.pc || "";
  const pcCookie = getCookie("pc");
  const postalCode = app.postalCode || pcCookie || pc;

  const { keycloak } = useKeycloak();
  const rhUser = useRhUserAtomValue();
  const { userType } = rhUser;

  const env = useEnv();
  const location = useLocation();
  const history = useHistory();
  const brandCode = useBrand();
  const prefix = useLocalization();
  const isLoadedGTM = isGTMLoaded();
  const { previousState } = useUserPreferences();
  const measureSystem = useMeasurementType();
  const locale = useLocale();
  const { setStorageValueWrapper } = useCookiesWithPermission();
  const mediaString = useMediaString();
  const country = getCountryFromUrl();
  const currencyCode = useCurrencyCode({
    country: previousState.country,
    postalCode: app.postalCode || pc,
    userType
  });
  const isSSRToggled = isSSRToggledCheck();
  const isSSRToggledWithCSR = isSSRToggledWithClientRender();

  const pathname = processEnvServer ? req?.path : location?.pathname;

  const client = useApolloClient();
  const siteId = useSite();

  const _params = useFetchParams(
    pathname,
    yn(env.FEATURE_URL_CHANGE) ? "pdp" : "product.jsp"
  );

  let [productId] = _params;

  if (params.productId === "") {
    params.productId = productId?.replace("&ct=true", "");
  }
  const { isConcierge } = useAppId();

  /**
   * Feature Flags
   * */
  const addAllToCartFlag = yn(env.FEATURE_PDP_ADD_ALL);
  const FEATURE_SUPPRESS_PRE_CONFIGUREED_SWATCH = yn(
    env.FEATURE_SUPPRESS_PRE_CONFIGUREED_SWATCH
  );

  const pdpLoadPerformance: boolean = processEnvServer
    ? req.cookies.FEATURE_LOAD_PERFORMANCE === "true"
    : env.FEATURE_LOAD_PERFORMANCE;
  /**
   * State Variables
   */
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [printFullSkuId, setPrintFullSkuId] = useState<string>("");
  const [trimOptions, setTrimOptions] = useState<string[] | null>(null);
  const [openGiftCardTerms, setOpenGiftCardTerms] = useState<boolean>(false);
  // More about RideAlong: SHOP-1647.
  const [rideAlongProduct, setRideAlongProduct] = useState<Product | null>(
    null
  );
  const [rideAlongData, setRideAlongData] =
    useState<RideAlongData>(defaultRideAlongData);
  /**
   * SHOP-1432 Maintains that any api call will merge with previous product data so it is never an empty object
   */
  const [combinedProduct, setCombinedProduct] = useState<any>({} as Product);
  const [gridColumns, setGridColumns] = useState<number>(DEFAULT_GRID_COL);
  const [
    refetchQueryProductSwatchImageLoading,
    setRefetchQueryProductSwatchImageLoading
  ] = useState(false);
  const [requestConsultationOpen, setRequestConsultationOpen] = useState(false);
  const [showFAQDialog, setFAQDialog] = useState(false);
  const [selectedLineItemOptionLabel, setSelectedLineItemOptionLabel] =
    useState(false);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(false);
  const analyticsModalStates = useRef({
    dimensionModal: false,
    detailsModal: false
  });
  const [selectedRugTrimSwatchId, setSelectedRugTrimSwatchId] = useState<
    string | undefined
  >(""); //only for custom rugs
  const [selectedRugTrimSwatchIds, setSelectedRugTrimSwatchIds] = useState<
    string[] | null
  >(null); //only for custom rugs
  const [swatchPanelDialog, , setSwatchPanelDialog] = useState({
    open: false
  });
  let [selectedSwatches, setSelectedSwatchesValue] = useState<{
    [key: string]: ProductSwatch;
  }>({});
  let [selectedHeroImageSwatchUrl, setSelectedHeroImageSwatchUrl] =
    useState("");
  const [isColorizable, setIsColorizable] = useState(true);
  const [openInStockOnSaleModal, setOpenInStockOnSaleModal] = useState(false);
  const [selectedOptionsFromLineItem, setSelectedOptions] = useState([]);
  const [isSwatchSwitch, setIsSwatchSwitch] = useState(0);
  const [selectedSwatch, setSelectedSwatch] = useState(null);
  const [allSelectedAddOnsConfigured, setAllSelectedAddOnsConfigured] =
    useState<boolean>(true);
  //add all item to cart
  const [addAllItemToCart, setAddAllItemToCart] = useState<
    Array<AddAllItemToCart>
  >([]);
  const [multiSkuComponentsData, setMultiSkuComponentsData] = useState<
    MultiSkuComponentWithRestrictions[]
  >([]);
  const [productAddonItems, setProductAddonItems] = useState<
    Array<addOnDataType>
  >([]);
  const [swatchImageOnLineItem, changeSwatchImageOnLineItem] = useState("");
  const [productCardContentHeight, updateProductCardContentHeight] =
    useState(0);

  const productCardContentRef = (ref: HTMLDivElement) => {
    if (!ref) return;

    updateProductCardContentHeight(ref?.offsetHeight);
  };

  /**
   * Computed Variables
   * */
  const req2 = getReqContext();
  const userAgent = req2?.headers["user-agent"];
  let mobile = false;
  if (userAgent) {
    const { isMobile } = getSelectorsByUserAgent(userAgent);
    mobile = isMobile;
  }
  const saleContextParam = useMemo(
    () => (params.sale === "true" ? "sale" : null) as SaleContextFilter,
    [params.sale]
  );
  const productVariables = useMemo(() => {
    return {
      productId: params.productId,
      filter: saleContextParam,
      userType: userType,
      siteId,
      currencyCode: currencyCode || "",
      measureSystem,
      locale: locale,
      postalCode: pc || app.postalCode
    };
  }, [
    params.productId,
    userType,
    siteId,
    currencyCode,
    measureSystem,
    locale,
    saleContextParam,
    pc
  ]);

  let {
    client: productClient,
    loading: isProductLoading,
    data: { product } = {} as Query,
    error: productError,
    refetch
  } = useQuery<Query>(queryProduct, {
    variables: productVariables,
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    // skip: !params.productId || skipCall,
    skip: !params.productId,
    errorPolicy: "all",
    ...(processEnvServer
      ? { fetchPolicy: "cache-and-network" }
      : { fetchPolicy: "cache-first" })
    // ...(processEnvServer ? {} : { fetchPolicy: "cache-only" })
  });

  // if (productItem && Object.keys(productItem)?.length) {
  //   product = productItem;
  // }
  const { data: { product: fullSkuProduct } = {} as Query } = useQuery<Query>(
    queryFullSkuProduct,
    {
      variables: {
        productId: params.productId,
        fullSkuId: params.fullSkuId
      },
      skip: !params.fullSkuId,
      onCompleted: () => {
        swatchToggle = 1;
      }
    }
  );
  const productSwatchQueryVariables = useMemo(() => {
    return {
      productId: params.productId,
      optionIds: options,
      siteId,
      locale,
      trimOptionIds: trimOptions ?? null,
      fullskuId: params?.fullSkuId ?? ""
    };
  }, [
    options,
    siteId,
    locale,
    trimOptions,
    params.fullSkuId,
    params.productId
  ]);

  const {
    loading: productSwatchLoading,
    data: { product: productSwatchImage } = {} as Query,
    refetch: refetchQueryProductSwatchImage
  } = useQuery<Query>(queryProductSwatchImage, {
    fetchPolicy: "cache-and-network",
    variables: productSwatchQueryVariables
  });
  let data = processEnvServer
    ? {
        // ...baseProduct,
        ...product,
        ...fullSkuProduct,
        ...productSwatchImage
      }
    : combinedProduct;
  const layoutTemplate =
    (
      {
        standard: "inline",
        "standard-rugs": "inline",
        "two-color": "panel",
        "single-color": "panel",
        outdoor: "split-panel",
        "single-color-split": "split-panel"
      } as { [layout: string]: "inline" | "panel" | "split-panel" }
    )[(processEnvServer ? data : combinedProduct).layout] || "panel";
  const animationCheck = !isSSRToggledWithCSR || history?.action === "PUSH";

  const shouldShowSaleText =
    (Number(
      (processEnvServer ? data : combinedProduct)?.priceRangeDisplay?.saleInfo
        ?.percentSaleSkus
    ) ?? 0) > 0;

  const selectItemsOnSale =
    Number(
      (processEnvServer ? data : combinedProduct)?.priceRangeDisplay?.saleInfo
        ?.percentSaleSkus
    ) > 0 &&
    Number(
      (processEnvServer ? data : combinedProduct)?.priceRangeDisplay?.saleInfo
        ?.percentSaleSkus
    ) < 100;

  const salePercentageRangeExists =
    (processEnvServer ? data : combinedProduct)?.priceRangeDisplay?.saleInfo
      ?.memberSavings?.memberSavingsMax !==
    (processEnvServer ? data : combinedProduct)?.priceRangeDisplay?.saleInfo
      ?.memberSavings?.memberSavingsMin;

  const SAVE_ON_SELECT_ITEMS = template(pageContent?.SAVE_ON_SELECT_ITEMS)({
    minPercent: (processEnvServer ? data : combinedProduct)?.priceRangeDisplay
      ?.saleInfo?.memberSavings?.memberSavingsMin,
    maxPercent: (processEnvServer ? data : combinedProduct)?.priceRangeDisplay
      ?.saleInfo?.memberSavings?.memberSavingsMax
  });

  const allProductAddons = (
    processEnvServer ? data : combinedProduct
  )?.productAddons?.productAddonsInfo?.map(addon => ({
    ...addon,
    mainItemProductId: (processEnvServer ? data : combinedProduct)?.id
  }));

  const SAVE_UP_TO_SELECT_ITEMS = template(
    pageContent?.SAVE_MIN_PERCENT_ON_SELECT_ITEMS
  )({
    minPercent: (processEnvServer ? data : combinedProduct)?.priceRangeDisplay
      ?.saleInfo?.memberSavings?.memberSavingsMin
  });
  const percentSaleSkus = Number(
    data?.priceRangeDisplay?.saleInfo?.percentSaleSkus
  );

  const saleMessageForPriceComponent =
    shouldShowSaleText && selectItemsOnSale
      ? salePercentageRangeExists
        ? SAVE_ON_SELECT_ITEMS
        : SAVE_UP_TO_SELECT_ITEMS
      : "";

  const dynamicMemberSavingsText = getMemberSavingsText(
    pageContent,
    percentSaleSkus,
    data?.priceRangeDisplay?.saleInfo?.memberSavings?.memberSavingsMin,
    data?.priceRangeDisplay?.saleInfo?.memberSavings?.memberSavingsMax
  );

  const isActiveInstockModal = useMemo(() => {
    return !!(processEnvServer ? data : params)?.inStock;
  }, [params?.inStock]);

  const pathLocale =
    getLocaleFromPath(
      processEnvServer ? (req ? req.path : "/") : location.pathname
    )?.locale || "/us/en/";

  const toogleModalState = booleanInput => !booleanInput;

  const onChangeSwatchImageFromLineItem = (imgUrl: string) => {
    setSelectedHeroImageSwatchUrl(imgUrl);
  };

  const onChangeSelectedOptions = options => {
    setSelectedOptions(options);
  };

  const swatchGroups = [
    ...((processEnvServer ? data : combinedProduct)?.swatchData
      ?.finishSwatchGroups ?? []),
    ...(layoutTemplate === "inline"
      ? (processEnvServer ? data : combinedProduct)?.swatchData?.swatchGroups ??
        []
      : [])
  ];

  const allSwatchGroups = [
    ...((processEnvServer ? data : combinedProduct)?.swatchData
      ?.finishSwatchGroups ?? []),
    ...((processEnvServer ? data : combinedProduct)?.swatchData?.swatchGroups ??
      [])
  ];

  const totalSwatchesLength =
    allSwatchGroups?.reduce(
      (acc, cur) =>
        acc + cur.customSwatches?.length || acc + cur.stockedSwatches?.length,
      0
    ) ?? 0;

  const stockedSwatchesLength =
    (processEnvServer
      ? data
      : combinedProduct
    )?.swatchData?.swatchGroups?.reduce(
      (acc, cur) => acc + cur.stockedSwatches.length,
      0
    ) ?? 0;
  const customSwatchesLength =
    (processEnvServer
      ? data
      : combinedProduct
    )?.swatchData?.swatchGroups?.reduce(
      (acc, cur) => acc + (cur.customSwatches?.length ?? 0),
      0
    ) ?? 0;
  const groupMaterial =
    (processEnvServer
      ? data
      : combinedProduct
    )?.swatchData?.swatchGroups?.reduce(
      (acc, cur) => acc || cur.groupMaterial,
      ""
    ) ?? "";

  const getSwatchImageUrl = (pImage: Maybe<ProductImage>) => {
    return pImage ? `${pImage.imageUrl}` : " ";
  };

  const handleSelectSwatchesValue = (group: any, swatch: any) => {
    setIsColorizable(combinedProduct?.colorizeInfo?.colorizable);
    setSelectedSwatchesValue({
      ...selectedSwatches,
      [group]: { ...swatch, groupMaterial: group }
    });
    if (processEnvServer) {
      selectedSwatches = { ...selectedSwatches, [group]: swatch };
    }
  };

  /**
   * Graphql Calls
   * */
  const fetchRideAlongProduct = useCallback<
    (productId: string, variables?: any) => Promise<Product>
  >(
    async (productId, variables) => {
      const {
        data: { product }
      } = await client.query({
        query: queryRideAlongProduct,
        variables: {
          productId,
          filter: saleContextParam || null,
          userType: userType,
          siteId,
          currencyCode,
          measureSystem,
          ...variables
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      return product;
    },
    [saleContextParam, siteId, client, currencyCode, measureSystem, userType]
  );

  const giftCardTerms =
    useQuery<Query>(queryGiftCardTerms, {
      variables: { key: "rh-gift-card-pdp" }
    }).data?.contentFragment?.text ?? "";

  // Ran into an issue where refetch throws an error instead of putting the error into the hook error output
  // see: https://github.com/apollographql/react-apollo/issues/3571
  // Found a workaround using the errorPolicy
  // errorPolicy info from apollo docs:
  //    "If you set errorPolicy to all, useQuery does not discard query
  //     response data, allowing you to render partial results."

  let upsellVariables: any = {
    filter: saleContextParam,
    userType: userType,
    siteId,
    currencyCode: currencyCode || "",
    measureSystem,
    locale: locale,
    postalCode: pc || app.postalCode
  };

  const upsellIds = product?.priceRangeDisplay?.upsellIds;

  const enableNextgenUpsell =
    useIsoCookies(["nextgenupsell"], true)?.nextgenupsell === "true";
  if (enableNextgenUpsell) {
    upsellVariables = { ...upsellVariables, productIds: upsellIds };
  } else {
    upsellVariables = { ...productVariables };
  }

  const { data: { upsellProducts } = {}, refetch: refetchUpSellProducts } =
    useQuery(queryUpsSellProducts, {
      variables: upsellVariables,
      skip: enableNextgenUpsell
        ? !upsellIds?.length
          ? true
          : false
        : !params.productId || !product?.id
    });

  useDidMountEffect(() => {
    setRefetchQueryProductSwatchImageLoading(true);
    refetchQueryProductSwatchImage(productSwatchQueryVariables).finally(() => {
      setRefetchQueryProductSwatchImageLoading(false);
    });
  }, [JSON.stringify(productSwatchQueryVariables)]);

  useDidMountEffect(() => {
    refetch(productVariables);
    refetchUpSellProducts(upsellVariables);
  }, [JSON.stringify(productVariables)]);

  const relatedProductVariable = useMemo(() => {
    return {
      productIds: product?.relatedProductIds,
      //passing null irrepective of sale=true in url
      filter: product?.emptyProduct === true ? saleContextParam : null,
      userType: userType,
      siteId,
      currencyCode: currencyCode || "USD",
      measureSystem,
      locale: locale,
      productId: params.productId
    };
  }, [
    userType,
    siteId,
    currencyCode,
    measureSystem,
    locale,
    saleContextParam,
    product?.relatedProductIds
  ]);

  const {
    data: { relatedProductsQuery } = {} as Query,
    loading: relatedProductsloading
  } = useQuery<Query>(queryRelatedProducts, {
    variables: relatedProductVariable,
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    skip: !Boolean(relatedProductVariable?.productIds?.length)
  });

  const { data: { contentFragment } = {} as Query } = useQuery<Query>(
    queryDraperyLink,
    {
      skip: product?.uxAttributes?.productType !== "drapery",
      errorPolicy: "ignore",
      variables: {
        key: `draperyLink-${brandCode}`
      }
    }
  );

  const relatedProducts: Product[] = useMemo(() => {
    const isMultiSkuProduct =
      (processEnvServer ? data : combinedProduct)?.metaProduct &&
      (processEnvServer ? data : combinedProduct)?.nextGenDriven === "true";

    const shouldShowMainProd =
      (!(processEnvServer ? data : combinedProduct)?.emptyProduct ||
        isMultiSkuProduct) &&
      (processEnvServer ? data : combinedProduct)?.productLineItem;

    const relatedProductsFromData: Product[] = [
      ...(shouldShowMainProd
        ? [processEnvServer ? data : combinedProduct]
        : []),
      ...(relatedProductsQuery ?? [])
    ];

    if (
      rideAlongData?.replacementCushionProduct &&
      rideAlongData?.casingProduct &&
      rideAlongProduct
    ) {
      return relatedProductsFromData.map(product =>
        product.id === rideAlongData?.replacementCushionProduct
          ? rideAlongProduct
          : product
      );
    }

    return relatedProductsFromData;
  }, [
    processEnvServer ? data : combinedProduct,
    rideAlongData,
    rideAlongProduct,
    relatedProductsQuery
  ]);

  const pli = relatedProducts[0]?.productLineItem;
  const [selectedAddOnsConfigured, setSelectedAddOnsConfigured] = useState<{
    [key: number]: boolean;
  }>(() => {
    return relatedProducts.reduce((acc, item, index) => {
      if (item?.productAddons) {
        acc[index] = false;
      }
      return acc;
    }, {} as { [key: number]: boolean });
  });

  const allRProductAddons = relatedProductsQuery?.reduce((acc, item) => {
    if (
      item.productAddons &&
      Array.isArray(item.productAddons.productAddonsInfo)
    ) {
      const updatedAddons = item.productAddons.productAddonsInfo.map(addon => ({
        ...addon,
        mainItemProductId: item.id
      }));
      return acc.concat(updatedAddons);
    }
    return acc;
  }, []);

  const handleClose = useCallback(() => {
    let currentUrlParams = new URLSearchParams(location.search);
    currentUrlParams.set("sale", (product?.onSale || "").toString());
    if (history) {
      history.replace({
        pathname: `${prefix}/catalog/product/product.jsp`,
        search: `?${currentUrlParams.toString()}`
      });
    }
    setOpen(!open);
  }, [product?.onSale, history, location?.search, open]);

  useEffect(() => {
    return () => {
      productClient?.cache?.reset();
      productClient?.cache?.gc();
    };
  }, [params?.productId]);

  useEffect(() => {
    async function fetchQuery() {
      let response = await fetchRideAlongProduct?.(
        rideAlongData.casingProduct ?? ""
      );
      setRideAlongProduct(response);
    }
    if (rideAlongData.casingProduct && !rideAlongProduct) {
      fetchQuery();
    }
  }, [rideAlongData.casingProduct, rideAlongProduct, fetchRideAlongProduct]);

  useEffect(() => {
    const longDescription =
      longDescriptionRef?.current ||
      document.getElementById("long_description");

    const anchorElements = Array.from(
      longDescription?.querySelectorAll("a") ?? []
    );

    anchorElements.map(anchorElement => {
      const href = anchorElement.getAttribute("href");
      const hasPgTerm = href.includes(PG_SEARCH_TERM);
      const sanitizedHref = addPgTermFallback(href);

      const categoryProductPattern =
        /\/catalog\/category\/(products|collections)\.jsp/;
      const productPattern = /\/catalog\/product\/product\.jsp/;
      const idPattern = /(categoryId|productId)=([a-zA-Z0-9]+)/;

      const hasCategoryOrProductRef =
        categoryProductPattern.test(href) || productPattern.test(href);

      const hasCategoryOrProductId =
        idPattern.test(href) || params?.productId?.toString()?.length;

      const hasCloudCollectionUrl = cloudCollectionUrlPattern.test(href);

      if (
        (hasCategoryOrProductRef && !hasPgTerm && hasCategoryOrProductId) ||
        hasCloudCollectionUrl
      ) {
        anchorElement.setAttribute("href", `${prefix}${sanitizedHref}`);
      }

      const anchorClass = anchorElement?.getAttribute("class");
      !(anchorClass && anchorClass === "modal-trigger") &&
        anchorElement.addEventListener("click", handleClick);
      return anchorElement;
    });

    return () => {
      anchorElements.map(anchorElement =>
        anchorElement.removeEventListener("click", handleClick)
      );
    };

    function handleClick(event: MouseEvent) {
      const href = (event.target as HTMLAnchorElement)?.getAttribute("href");

      if (href?.includes("images")) {
        (event.target as HTMLAnchorElement)?.setAttribute("target", "_blank");
      } else {
        event.preventDefault();
        history.push(href);
      }
    }
    // TODO: Fix eslint error and remove this comment block
    // React Hook useEffect has a missing dependency: 'data.emptyProduct'.
    // Either include it or remove the dependency array  react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedProduct?.longDescription, history]);

  useEffect(() => {
    if (!processEnvServer && swatchPanelDialog["open"]) {
      document.body.dispatchEvent(
        new CustomEvent("pre-pdp-click", {
          detail: {
            item: {
              action: "Swatch collection expand"
            }
          }
        })
      );
    }
  }, [swatchPanelDialog["open"]]);

  useEffect(() => {
    if (!processEnvServer) {
      w.hardReload = false;
      const handlePageLoad = () => {
        w.hardReload = true;
      };
      window.addEventListener("readystatechange", handlePageLoad);
      return () => {
        window.removeEventListener("readystatechange", handlePageLoad);
      };
    }
  }, []);

  useDidMountEffect(() => {
    if (!smUp) {
      setGridColumns(6);
    } else {
      setGridColumns(4);
    }
  }, [mdUp, smUp, smDown]);

  useEffect(() => {
    if (productSwatchLoading || refetchQueryProductSwatchImageLoading) {
      return;
    }
    setCombinedProduct(prevCombinedProduct => {
      return {
        // SHOP-1432 Including previous state in the spread keeps the drop down options selected
        ...prevCombinedProduct,
        ...product,
        ...fullSkuProduct,
        ...productSwatchImage
      };
    });
  }, [
    fullSkuProduct,
    product,
    productSwatchImage,
    productSwatchLoading,
    refetchQueryProductSwatchImageLoading
  ]);

  useEffect(() => {
    async function fetchQuery() {
      let response = await fetchRideAlongProduct?.(
        rideAlongData.casingProduct ?? ""
      );
      setRideAlongProduct(response);
    }
    if (processEnvServer && rideAlongData.casingProduct && !rideAlongProduct) {
      fetchQuery();
    }
  }, [rideAlongData.casingProduct, rideAlongProduct, fetchRideAlongProduct]);

  // consolidated selectSwatchValue calls
  useEffect(() => {
    setOptions?.(selectedOptionsFromLineItem.map(e => e.id));
    const label = selectedOptionsFromLineItem.map(e => e.value);
    setSelectedOptionLabel(!!label[0]);
  }, [selectedOptionsFromLineItem]);

  useEffect(() => {
    const options: any = Object.keys(selectedSwatches)
      .map(key => {
        const allOptions = selectedSwatches[key].options.map(option => {
          return option;
        });
        return allOptions;
      })
      .flat();
    setOptions?.(options.map(e => e.id));
    const label = options.map(e => e.label);
    setSelectedOptionLabel(!!label[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSwatches, combinedProduct]);

  useEffect(
    () => setTrimOptions?.(selectedRugTrimSwatchIds),
    [selectedRugTrimSwatchIds]
  );

  const isProductLoadingFirstTime = useChangeOnce(isProductLoading);
  const longDescriptionRef = useRef<HTMLDivElement>(null);

  const updateSwatchGroups = () => {
    swatchGroups.forEach(swatchGroup => {
      swatchGroup?.customSwatches?.forEach(customSwatch => {
        if (combinedProduct.fullSkuSwatchIds?.includes(customSwatch.swatchId)) {
          handleSelectSwatchesValue(swatchGroup.groupMaterial, customSwatch);
        }
      });

      swatchGroup?.stockedSwatches?.forEach(stockedSwatch => {
        if (
          combinedProduct?.fullSkuSwatchIds?.includes(stockedSwatch.swatchId)
        ) {
          handleSelectSwatchesValue(swatchGroup.groupMaterial, stockedSwatch);
        }
      });
    });
  };

  if (swatchToggle == 1 && isSSRToggledWithCSR) {
    updateSwatchGroups();
    swatchToggle += 1;
  }

  useUnsafeEffect(() => {
    if (isSSRToggledWithCSR) {
      return;
    }
    swatchGroups.forEach(swatchGroup => {
      swatchGroup?.customSwatches?.forEach(customSwatch => {
        if (
          combinedProduct?.fullSkuSwatchIds?.includes(customSwatch.swatchId)
        ) {
          handleSelectSwatchesValue(swatchGroup.groupMaterial, customSwatch);
        }
      });
      if (swatchGroup?.stockedSwatches?.length === 1) {
        handleSelectSwatchesValue(
          swatchGroup.groupMaterial,
          swatchGroup?.stockedSwatches[0]
        );
      } else {
        swatchGroup?.stockedSwatches?.forEach(stockedSwatch => {
          if (
            combinedProduct?.fullSkuSwatchIds?.includes(stockedSwatch.swatchId)
          ) {
            handleSelectSwatchesValue(swatchGroup.groupMaterial, stockedSwatch);
          }
        });
      }
    });
  }, [combinedProduct?.fullSkuSwatchIds, combinedProduct?.swatchData]);

  let triggerSwatchImage = "";
  if (
    (processEnvServer ? data : combinedProduct)?.uxAttributes &&
    (processEnvServer ? data : combinedProduct)?.uxAttributes.triggerSwatchImage
  ) {
    triggerSwatchImage = (processEnvServer ? data : combinedProduct)
      ?.uxAttributes.triggerSwatchImage;
  } else {
    if (
      (processEnvServer ? data : combinedProduct)?.layout === "single-color" ||
      (processEnvServer ? data : combinedProduct)?.layout === "two-color"
    ) {
      triggerSwatchImage = (
        {
          Leather: `<img src="//media.restorationhardware.com/is/image/rhis/hpdp_swatch_leather_20160405?wid=674&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`,
          Fabric: `<img src="//media.restorationhardware.com/is/image/rhis/hpdp_swatch_fabric_20160405?wid=674&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`
        } as { [key: string]: string }
      )[groupMaterial];
    } else if (
      (processEnvServer ? data : combinedProduct)?.layout === "outdoor"
    ) {
      triggerSwatchImage = `<img src="//media.restorationhardware.com/is/image/rhis/OD_Fabric_Swatch19_1146002?wid=674&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`;
    } else {
      triggerSwatchImage = (
        {
          Leather: `<img src="//media.restorationhardware.com/is/image/rhis/hpdp_swatch_leather_20160405?wid=674&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`,
          Fabric: `<img src="//media.restorationhardware.com/is/image/rhis/btn_pdp_cloud_uph_072016?wid=320&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`
        } as { [key: string]: string }
      )[groupMaterial];
    }
  }

  const checkSelectedAddOnsForConfig = (
    index: number | any,
    condition: boolean
  ) => {
    setSelectedAddOnsConfigured(prevConditions => {
      const newConditions = { ...prevConditions, [index]: condition };
      const allTrue = Object.values(newConditions).every(cond => !cond);
      setAllSelectedAddOnsConfigured(allTrue);
      return newConditions;
    });
  };

  // on HeroImage onIndexChange callback, reset HeroImage swatch url only
  const handleHeroImageOnIndexChange = useCallback(() => {
    params?.fullSkuId ? null : setSelectedHeroImageSwatchUrl("");
  }, []);

  const shouldShow = combinedProduct?.isActive ?? true;

  const handleAddAllItemToCart = useCallback(
    (data: AddAllItemToCart) => {
      const { monogram, customInfo } = data;
      //filter data according to the lineItemIndex
      const uniqueItems = addAllItemToCart.filter(
        item => item.lineItemIndex !== data?.lineItemIndex
      );

      const allItems = [
        ...uniqueItems,
        {
          ...data,
          ...(monogram?.fontCode ||
          monogram?.fontColorCode ||
          monogram?.borderCode
            ? { monogram }
            : null),
          ...(!isEmpty(customInfo) ? { customInfo } : null)
        }
      ];
      const filteredData = allItems?.filter(
        item => item?.quantity > 0 && item?.sku
      );
      setAddAllItemToCart([...filteredData]);
    },
    [addAllItemToCart]
  );

  const handleRemoveItemFromAddAll = useCallback(
    (lineItemIndex: number) => {
      const matchedLineItemIndex = addAllItemToCart?.findIndex(
        item => item?.lineItemIndex === lineItemIndex
      );
      if (matchedLineItemIndex >= 0) {
        const filteredData = addAllItemToCart?.filter(
          item => item?.lineItemIndex !== lineItemIndex
        );
        setAddAllItemToCart([...filteredData]);
      }
    },
    [addAllItemToCart]
  );

  // consolidated onSwatchClick and isColorizable, and set HeroImage swatch url

  const handleOnSwatchClick = useCallback(
    (swatchData: {
      swatchGroup: ProductSwatchGroup;
      selectedSwatch: ProductSwatch;
    }) => {
      let count = isSwatchSwitch;
      if (swatchData?.selectedSwatch) {
        setIsSwatchSwitch(++count);
        setSelectedSwatch(swatchData?.selectedSwatch);
      }
      setSelectedOptions([]);
      if (swatchData?.selectedSwatch?.options[0]?.optionType === "Trim") {
        // only for custom rugs with trim color option
        setSelectedRugTrimSwatchIds(
          swatchData.selectedSwatch.options.map(op => op.id)
        );
        setSelectedRugTrimSwatchId(swatchData.selectedSwatch.secondaryOptionId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      } else {
        handleSelectSwatchesValue(
          swatchData?.swatchGroup?.groupMaterial,
          swatchData?.selectedSwatch
        );
        setSelectedRugTrimSwatchId("");
      }
    },
    [combinedProduct, isColorizable]
  );
  const findDefaultSaleSwatch = useCallback(
    (groupMaterial, swatchId) => {
      let selectedSwatchGroup = null;
      const finishSwatchGroups = (processEnvServer ? data : combinedProduct)
        ?.swatchData?.finishSwatchGroups;

      // If finishSwatchGroups exist, find the swatch group with an empty groupMaterial as the API returns an empty groupMaterial
      if (finishSwatchGroups && finishSwatchGroups.length > 0) {
        selectedSwatchGroup = swatchGroups.find(
          group =>
            group?.groupMaterial === "Finish" ||
            group?.groupMaterial?.includes("Finish")
        );
      } else {
        // If finishSwatchGroups don't exist, find the swatch group based on groupMaterial
        selectedSwatchGroup = swatchGroups.find(
          group => group.groupMaterial === groupMaterial
        );
      }

      if (!selectedSwatchGroup) {
        return null;
      }

      const selectedSwatchId = swatchId?.toString();
      const selectedSwatch = selectedSwatchGroup.stockedSwatches.find(
        swatch => swatch.swatchId === selectedSwatchId
      );

      if (selectedSwatch) {
        return {
          swatchGroup: selectedSwatchGroup,
          selectedSwatch
        };
      }

      return null;
    },
    [
      (processEnvServer ? data : combinedProduct)?.swatchData
        ?.finishSwatchGroups,
      swatchGroups
    ]
  );

  const isPostalCodeAvailable = useMemo(() => {
    return (
      !!postalCode &&
      postalCode !== "null" &&
      postalCode !== "undefined" &&
      postalCode !== "false"
    );
  }, [postalCode]);

  const priceRangeDisplay = useMemo(() => {
    const isMultiSkuPopulated =
      !!(processEnvServer ? data : combinedProduct)
        ?.priceRangeMultiSkuDisplay &&
      !(processEnvServer ? data : combinedProduct)?.priceRangeMultiSkuDisplay
        .isUnavailable &&
      !(processEnvServer ? data : combinedProduct)?.metaProduct;

    return isMultiSkuPopulated
      ? {
          ...(processEnvServer ? data : combinedProduct)?.priceRangeDisplay,
          listPrices: (processEnvServer ? data : combinedProduct)
            ?.priceRangeMultiSkuDisplay?.listPrices,
          memberPrices: (processEnvServer ? data : combinedProduct)
            ?.priceRangeMultiSkuDisplay?.memberPrices,
          skulowestMemberPrice: (processEnvServer ? data : combinedProduct)
            ?.priceRangeMultiSkuDisplay?.skulowestMemberPrice,
          nextGenDrivenOnSale: (processEnvServer ? data : combinedProduct)
            ?.priceRangeMultiSkuDisplay?.nextGenDrivenOnSale
        }
      : (processEnvServer ? data : combinedProduct)?.priceRangeDisplay;
  }, [
    (processEnvServer ? data : combinedProduct)?.priceRangeDisplay,
    (processEnvServer ? data : combinedProduct)?.priceRangeMultiSkuDisplay
  ]);

  const handleFAQDialogOpen = useCallback(() => setFAQDialog(true), []);

  const handleFAQDialogClose = useCallback(() => setFAQDialog(false), []);

  const handleRequestConsultationOpen = useCallback(
    () => setRequestConsultationOpen(true),
    []
  );

  const handleRequestConsultationClose = useCallback(
    () => setRequestConsultationOpen(false),
    []
  );

  const handleCloseInStockOnSaleModal = useCallback(
    () => setOpenInStockOnSaleModal(false),
    []
  );

  const styledDescription = (
    processEnvServer ? data : combinedProduct
  )?.longDescription?.replace(
    /(<a\s+class="sku-info link")(.*?)(>)/g,
    `<a$2 style="text-transform: uppercase; font-family: 'RHSANS-THIN'; text-decoration: none; font-weight: 300; line-height: 13px; font-size: 13px;"$3`
  );

  const isShellProduct = (processEnvServer ? data : combinedProduct)
    ?.emptyProduct;

  useEffect(() => {
    if (!params?.swatch && params?.fullSkuId) {
      return;
    }
    const selectedSwatch = findDefaultSaleSwatch(
      "Finish",
      params?.swatch ||
        (processEnvServer ? data : combinedProduct)?.saleSwatchId
    );
    if (selectedSwatch && !FEATURE_SUPPRESS_PRE_CONFIGUREED_SWATCH) {
      handleOnSwatchClick(selectedSwatch);
    }
  }, [
    (processEnvServer ? data : combinedProduct)?.swatchData,
    (processEnvServer ? data : combinedProduct)?.saleSwatchId,
    params?.swatch
  ]);

  if (processEnvServer) {
    selectedHeroImageSwatchUrl = getSwatchImageUrl(
      (processEnvServer ? data : combinedProduct)?.productSwatchImage
    );
  }

  const containerGridSpacing = (gridSpacing * 2) as GridSpacing;

  const handleOpenInStockOnSaleModal = useCallback(() => {
    setOpenInStockOnSaleModal(true);
  }, []);

  const parsedDerivedUpsellProductList = useMemo(() => {
    const maxContainerHeight = upsellProducts?.upsellProducts?.reduce(
      (accMaxContainerHeight: number, itm: any) => {
        const imgContainerHeight = prasePGCropRules(itm?.pgCropRules)?.height;

        const [MAX_IMG_CONTAINER_HEIGHT] =
          PG_IMAGE_CONTAINER_DIMENSION?.[gridColumns]?.[mediaString] ??
          INITIAL_PG_IMAGE_CONTAINER_DIMENSION;

        const imageContainerHeight =
          (imgContainerHeight / 100) * MAX_IMG_CONTAINER_HEIGHT;

        return accMaxContainerHeight >= imageContainerHeight
          ? accMaxContainerHeight
          : imageContainerHeight;
      },
      0
    );
    return upsellProducts?.upsellProducts?.map((groupedDerivedProduct: any) => {
      const [MAX_IMG_CONTAINER_HEIGHT] =
        PG_IMAGE_CONTAINER_DIMENSION?.[gridColumns]?.[mediaString] ??
        INITIAL_PG_IMAGE_CONTAINER_DIMENSION;

      const imgHeight = `${
        (prasePGCropRules(groupedDerivedProduct?.pgCropRules)?.height / 100) *
        MAX_IMG_CONTAINER_HEIGHT
      }px`;

      return {
        ...groupedDerivedProduct,
        imageStyle: {
          objectFit: "fill",
          alignSelf: "flex-end",
          maxWidth: "100% !important",
          maxHeight: groupedDerivedProduct?.rhr ? imgHeight : "auto",
          width: "auto",
          height: "auto",
          transitionProperty: "opacity"
        },
        imageContainerStyle: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: groupedDerivedProduct?.rhr ? maxContainerHeight : "100%",
          aspectRatio: groupedDerivedProduct?.rhr
            ? null
            : IMAGE_ASPECT_RATIO["verticalProductTile"]?.toString()
        },
        isShopByRoom: true
      };
    });
  }, [upsellProducts?.upsellProducts, mediaString, gridColumns]);

  const upsellProductsUI = parsedDerivedUpsellProductList?.map(
    (upsellProduct, i) => (
      <GridListTile
        key={`upsellProduct-${upsellProduct.id}`}
        classes={{ tile: "flex items-end justify-center" }}
        className="md:w-auto w-[81%] md:max-w-none max-w-[310px] pr-5"
      >
        <ProductCard
          {...(i === 0 && { cardContentRef: productCardContentRef })}
          data={upsellProduct as any}
          upsell="upsell"
          objectFit={"contain"}
          colorBg={PAGE_BG_GREY}
          onViewSelectItemsOnSaleClick={handleOpenInStockOnSaleModal}
          cardMediaStyles={
            processEnvServer && mobile && { height: "164px !important" }
          }
          RHImageStyles={processEnvServer && mobile && { maxHeight: "68.8px" }}
        />
      </GridListTile>
    )
  );

  const customProductsCarousel = React.useMemo(
    () =>
      combinedProduct?.customProduct
        ? combinedProduct?.carouselProdctDetails?.map((customProduct, i) => {
            const newCustomProduct = { ...customProduct };
            newCustomProduct.id = customProduct.productId;

            return (
              <GridListTile
                key={`customProduct-${newCustomProduct?.id}`}
                className="md:max-w-[450px]"
              >
                <ProductCard
                  {...(i === 0 && { cardContentRef: productCardContentRef })}
                  data={newCustomProduct as any}
                  upsell=""
                  showPriceRange={false}
                  colorBg={PAGE_BG_GREY}
                />
              </GridListTile>
            );
          })
        : null,
    [combinedProduct?.carouselProdctDetails]
  );

  const draperyLinkFilepath = contentFragment?.text?.match(filePathRegex);

  const processedDraperyLink =
    draperyLinkFilepath?.length > 0
      ? contentFragment?.text?.replace(
          draperyLinkFilepath[0],
          convertPathLocale(draperyLinkFilepath[0], "/us/en/", pathLocale)
        )
      : contentFragment?.text;

  if ((!pdpLoadPerformance && processEnvServer) || isConcierge) {
    const { data: { upsellProducts } = {}, refetch: refetchUpSellProducts } =
      useQuery(queryUpsSellProducts, {
        variables: upsellVariables,
        skip: !upsellIds?.length ? true : false
      });

    _upsellProducts = upsellProducts;
    _refetchUpSellProducts = refetchUpSellProducts;

    const relatedProductVariable = useMemo(() => {
      return {
        productIds: product?.relatedProductIds,
        //passing null irrepective of sale=true in url
        filter: product?.emptyProduct === true ? saleContextParam : null,
        userType: userType,
        siteId,
        currencyCode: currencyCode || "USD",
        measureSystem,
        locale: locale
      };
    }, [
      userType,
      siteId,
      currencyCode,
      measureSystem,
      locale,
      saleContextParam,
      product?.relatedProductIds
    ]);

    _relatedProductVariable = relatedProductVariable;

    const {
      data: { relatedProductsQuery } = {} as Query,
      loading: relatedProductsloading
    } = useQuery<Query>(queryRelatedProducts, {
      variables: relatedProductVariable,
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      skip: !Boolean(relatedProductVariable?.productIds?.length)
    });

    _relatedProductsQuery = relatedProductsQuery;
    _relatedProductsloading = relatedProductsloading;
  }

  const { printFrameRef, enabled, showLoader, injectIframe, setIframeLoaded } =
    usePrintHijack({
      enabled: yn(env.FEATURE_IFRAME_PRINT),
      spyElementId: "pdp-print-load-check"
    });

  const printSrc = useMemo(() => {
    return `${prefix}/print-product.jsp?${objectToQueryString({
      ...productVariables,
      userType,
      sale: params.sale,
      fullSkuId:
        printFullSkuId ||
        (processEnvServer ? data : combinedProduct)?.preconfiguredSku ||
        params.fullSkuId
    })}`;
  }, [
    (processEnvServer ? data : combinedProduct)?.sku?.fulfillmentEta?.fullSkuId,
    (processEnvServer ? data : combinedProduct)?.sku?.inventory?.fullSkuId,
    prefix,
    productVariables,
    userType,
    params
  ]);
  data = processEnvServer
    ? {
        // ...baseProduct,
        ...product,
        ...fullSkuProduct,
        ...productSwatchImage
      }
    : combinedProduct;

  return {
    pdpLoadPerformance,
    data,
    upSellProducts: processEnvServer ? _upsellProducts : upsellProducts,
    upsellProductsUI,
    fullSkuId: params.fullSkuId || undefined,
    draperyLink: contentFragment?.text ?? "",
    authenticated: keycloak?.authenticated,
    saleContextFilter: saleContextParam,
    productSwatchLoading: processEnvServer
      ? productSwatchLoading
      : productSwatchLoading || refetchQueryProductSwatchImageLoading,
    // isProductLoading: processEnvServer
    //   ? isProductLoadingFirstTime
    //   : (isSSRToggledWithCSR &&
    //       !document.referrer &&
    //       w.hardReload &&
    //       history.action !== "PUSH") ||
    //     (isSSRToggledWithCSR && window.location.href.includes("product.jsp/"))
    //   ? false
    //   : isProductLoadingFirstTime,
    isProductLoading,
    postalCode: postalCode,
    rideAlongProduct: rideAlongProduct,
    rProducts: relatedProductsQuery,
    gridColumns,
    locale,
    pathLocale,
    selectedSwatchImageUrl: combinedProduct?.productLineItem?.image?.imageUrl,
    relatedProductVariable: processEnvServer
      ? _relatedProductVariable
      : relatedProductVariable,
    relatedProductsloading: processEnvServer
      ? _relatedProductsloading
      : relatedProductsloading,

    isColorizable,
    changeSwatchImageOnLineItem,
    selectedHeroImageSwatchUrl,
    getSwatchImageUrl,
    productSwatchImage,
    handleHeroImageOnIndexChange,
    selectedOptionLabel,
    isSwatchSwitch,
    totalSwatchesLength,
    selectedSwatch,
    xlUp,
    mdUp,
    xsDown,
    containerGridSpacing,
    animationCheck,
    shouldShow,
    pageContent,
    priceRangeDisplay,
    params,
    rhUser,
    saleMessageForPriceComponent,
    dynamicMemberSavingsText,
    handleOpenInStockOnSaleModal,
    openInStockOnSaleModal,
    handleCloseInStockOnSaleModal,
    isPostalCodeAvailable,
    longDescriptionRef,
    styledDescription,
    handleRequestConsultationOpen,
    requestConsultationOpen,
    handleRequestConsultationClose,
    layoutTemplate,
    stockedSwatchesLength,
    customSwatchesLength,
    setSwatchPanelDialog,
    groupMaterial,
    triggerSwatchImage,
    swatchGroups,
    selectedOptionsFromLineItem,
    selectedSwatches,
    handleOnSwatchClick,
    openGiftCardTerms,
    setOpenGiftCardTerms,
    giftCardTerms,
    relatedProducts,
    pli,
    rideAlongData,
    setRideAlongData,
    setMultiSkuComponentsData,
    setProductAddonItems,
    setPrintFullSkuId,
    checkSelectedAddOnsForConfig,
    isShellProduct,
    analyticsModalStates,
    toogleModalState,
    filePathRegex,
    handleFAQDialogOpen,
    showFAQDialog,
    handleFAQDialogClose,
    processedDraperyLink,
    allSwatchGroups,
    setSelectedHeroImageSwatchUrl,
    swatchImageOnLineItem,
    setSelectedOptionLabel,
    selectedLineItemOptionLabel,
    setSelectedLineItemOptionLabel,
    onChangeSwatchImageFromLineItem,
    onChangeSelectedOptions,
    handleAddAllItemToCart,
    handleRemoveItemFromAddAll,
    selectedRugTrimSwatchId,
    addAllToCartFlag,
    addAllItemToCart,
    multiSkuComponentsData,
    productAddonItems,
    allProductAddons,
    allRProductAddons,
    allSelectedAddOnsConfigured,
    productCardContentHeight,
    customProductsCarousel,
    isSSRToggledWithCSR,
    swatchPanelDialog,
    isActiveInstockModal,
    printFrameRef,
    enabled,
    showLoader,
    injectIframe,
    setIframeLoaded,
    printSrc
  };
};
