import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import { ProductProps } from "@RHCommerceDev/page-product-v2";
import PDPProductDetails from "@RHCommerceDev/component-pdp-product-details";
import { useProductData } from "./useProductData";
import BaseProductSkeleton from "@RHCommerceDev/skeleton-base-product";
import PDPHeroImage from "@RHCommerceDev/component-pdp-hero-image";
import PDPSwatchCarousel from "@RHCommerceDev/component-pdp-swatch-carousel";

const PDP = memoize((props: ProductProps) => {
  const {
    data,
    isProductLoading,
    priceRangeDisplay,
    pageContent,
    isColorizable,
    selectedHeroImageSwatchUrl,
    selectedSwatch,
    selectedOptionLabel,
    totalSwatchesLength,
    isSwatchSwitch,
    changeSwatchImageOnLineItem,
    getSwatchImageUrl,
    handleHeroImageOnIndexChange
  } = useProductData(props);

  const ProductInfo = {
    category: { name: "Sofa", sortOrder: 3 },
    width: { name: "8 ft.", sortOrder: 2 },
    product: { name: data?.displayName, sortOrder: 1 },
    price: { name: data?.displayName, sortOrder: 4 }
  };

  return (
    <div className="px-10">
      {isProductLoading ? (
        <BaseProductSkeleton />
      ) : (
        <>
          <PDPProductDetails
            data={ProductInfo}
            className="mb-[19px]"
            priceProps={{
              listPrice: priceRangeDisplay?.listPrices?.[0]!,
              topLabel: pageContent?.["STARTING_AT"]
            }}
          />
          <PDPHeroImage
            changeSwatchImageOnLineItem={changeSwatchImageOnLineItem}
            objectFit={"contain"}
            images={data?.alternateImages}
            isColorizable={isColorizable}
            selectedSwatchImageUrl={
              selectedHeroImageSwatchUrl == ""
                ? getSwatchImageUrl(data?.productSwatchImage as any)
                : selectedHeroImageSwatchUrl
            }
            onIndexChange={handleHeroImageOnIndexChange}
            selectedOptionLabel={selectedOptionLabel}
            isRHR={data.rhr}
            isBannerVisible={totalSwatchesLength > 1}
            isSwatchSwitch={isSwatchSwitch}
            saleSwatchId={data?.saleSwatchId}
            selectedSwatch={selectedSwatch}
            imagePresetOverride={data?.imagePresetOverride}
          />
          <PDPSwatchCarousel
            swatchGroup={data?.swatchData?.swatchGroups}
            finishSwatchGroups={data?.swatchData?.finishSwatchGroups}
          />
        </>
      )}
    </div>
  );
});

export default PDP;
